import React, {useEffect} from 'react'
import {FaCheckCircle} from "react-icons/fa";

export default function FinishExam() {



    return (
        <main>

            <section className="d-flex align-items-center justify-content-center">


                <div className="container">
                    <div className="row my-5">
                        <div
                            className="col-12 text-center d-flex align-items-center justify-content-center"
                        >
                            <div>
                                <FaCheckCircle color={"green"} size={200}/>


                            </div>

                        </div>


                        <div
                            className="col-12 text-center d-flex align-items-center justify-content-center my-5"
                        >

                            <div style={{color: "green"}}>
                                <h2>
                                    YOU HAVE COMPLETED THE EXAM.<br/>
                                    THANK YOU FOR YOUR ATTENDANCE.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
