import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";

import ExamService   from "../../service/exam.service";
import {AppContext} from "../../../configs/AppContextProvider";

export default function StudentExamList() {
    const navigate = useNavigate();
    let {assignmentId} = useParams();

    const { exam, setLocalData } = useContext(AppContext);

    const [examList, setExamList] = useState([]);
    const [isReady, setIsReady] = useState("waiting");
    const [user, setUser] = useState(null);

    useEffect(() => {
        localStorage.setItem("assignmentId", assignmentId);
        ExamService.startExam(assignmentId).then((response) => {
            if(response.data){
                setIsReady("ready");
            }
            else{
                setIsReady("error");

            }
            setExamList(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(examList) {
            setLocalData(examList);
        }

        if(examList){
            setIsReady("ready");
        }
        else{
            setIsReady("error");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examList]);

    useEffect(() => {
        try{

            const student = JSON.parse(localStorage.getItem("student"));
            setUser(`${student.name} ${student.lastName}`)
        }catch (error) {
            console.log(error)
        }

    });



    useEffect(() => {

     if(isReady === "ready" && exam){
         navigate("/");
     }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exam]);


    return (
        <React.Fragment>


            <div className="container">
                <div className="card card-body shadow border-0 table-wrapper table-responsive mt-3">
                    <div className="d-flex mb-3">
                    </div>
                    {
                        isReady === "ready" ?  <Link className="btn btn-success text-white" to="/"><h3>
                                Hello {user}. If you are ready, we can start.
                            </h3></Link> :
                            isReady === "error" ? <h1>HATALI GİRİŞ</h1> : null
                    }
                </div>
            </div>

            <div>

                {//studentId}, {name}, {lastname}, {email}, {mobilePhone}, {school}, {grade}, {branch}, {examId
                }
            </div>


        </React.Fragment>
    )
}