import React, {Component, createContext} from "react";

export const AppContext = createContext();

class AppContextProvider extends Component {
    state = {
        student: true,
        exam: null,
        examResult: null,
        examResultActivityPart: null,
        examLink: null,
        examData: null,
        question: null
    };

    setExamData = (examData) => {
        this.setState({examData});
        this.setQuestion(0)
    };

    setQuestion = (qNumber) => {
        if (this.state.exam) {
            const _question = this.state.exam.activities[qNumber]
            this.setState({question: _question});
        }
    };

    setStudent = (student) => {
        this.setState({student});
    };
    setExamLink = (examLink) => {
        this.setState({examLink});
        localStorage.setItem("examLink", examLink);
    };

    setExam = (exam) => {
        this.setState({exam});
    };
    setExamResult = (examResult) => {
        this.setState({examResult});
    };

    setExamResultActivityPart = (examResultActivityPart) => {
        this.setState({examResultActivityPart});
    };

    setLocalData = (data) => {
        this.setStudent(data.student);
        this.setExam(data.exam);
        this.setExamResult(data.examResult);
        this.setExamResultActivityPart(data.examResultActivityPart);
        localStorage.setItem("data", JSON.stringify(data));
        localStorage.setItem("student", JSON.stringify(data.student));
        localStorage.setItem("exam", JSON.stringify(data.exam));
        localStorage.setItem("examResult", JSON.stringify(data.examResult));
        localStorage.setItem("examResultActivityPart", JSON.stringify(data.examResultActivityPart));
    };

    render() {
        return (
            <AppContext.Provider
                value={{
                    ...this.state,
                    setStudent: this.setStudent,
                    setExam: this.setExam,
                    setExamResult: this.setExamResult,
                    setExamResultActivityPart: this.setExamResultActivityPart,
                    setLocalData: this.setLocalData,
                    setExamLink: this.setExamLink,
                    setExamData: this.setExamData,
                    setQuestion: this.setQuestion
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppContextProvider;
