import React, {Component} from 'react'
import SpeechToText from 'speech-to-text';


export default class SpeechText extends Component {
    state = {
        interimText: '',
        listening: false,
        buttonText: 'Record Your Voice'
    };

    onAnythingSaid = text => {
        if (this.state.listening) {
            var konum = text.length;
            konum = konum / 2;
            var text1 = text.substring(0, konum);
            text1 = text1.toLowerCase().trim();
            var text2 = text.substring(konum);
            text2 = text2.toLowerCase().trim();
            if (text1 === text2) {
                text = text1;
            }
            this.setState({interimText: text});
        }
    };

    onEndEvent = () => {

    };

    onFinalised = text => {
        this.stopListening()
    };

    startListening = () => {
        if (this.props.recordRepeatCount < this.props.repeatCount) {
        try {
            this.listener = new SpeechToText(
                this.onFinalised,
                this.onEndEvent,
                this.onAnythingSaid,
                'en-US'
            );
            this.listener.startListening();
            this.setState({listening: true, buttonText: 'Recording...'});
        } catch (err) {
            console.log(err);
        }
        }
    };

    stopListening = () => {
        this.listener.stopListening();
        if (this.props.recordRepeatCount >= this.props.repeatCount -1) {
            this.setState({listening: false, buttonText: 'Record Completed'});
        } else {
            this.setState({listening: false, buttonText: 'Record Completed. If you want try again.'});
        }
        this.props.getSpeechText(this.state.interimText, this.props.questionNumber)
    };
    /*
        kayıt kırmızı
        il kez ise yeşil
        tekrar yapılacak ise sarı

     */
//  <button className={this.state.listening ? "btn btn-danger" : "btn btn-success"} onClick={() => this.startListening()}>{this.state.buttonText}</button>   warning

    componentDidUpdate(prevProps) {
        if (this.props.recordRepeatCount !== prevProps.recordRepeatCount) {
            this.setState({
                recordRepeatCount: this.props.recordRepeatCount
            });
            console.log(this.props.recordRepeatCount)
        }
    }

    componentDidMount() {
        if (this.props.recordRepeatCount >= this.props.repeatCount) {
            this.setState({listening: false, buttonText: 'Repeat Completed'});
        }
        else if(this.props.recordRepeatCount > 0 ){
            this.setState({listening: false, buttonText: 'Repeat Completed. If you want try again.'});
        }
    }


    render() {
        return (
            <div className="row w-100" key={this.props.key + "rec"}>

                <div className="col-auto">
                    <button
                        className={this.state.listening ? "btn btn-danger" : this.props.recordRepeatCount >= this.props.repeatCount ? "btn btn-light" : "btn btn-success"}
                        onClick={() => this.startListening()}>{this.state.buttonText}</button>
                </div>
                <div className="col">
                    <input className='form-control' type='text' value={this.state.interimText}
                           id="speechTextInput"></input>
                </div>
            </div>
        )
    }
}
