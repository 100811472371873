import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ExamService from "../../service/exam.service";
import SpeechText from "../../spech/SpeechText";
import PlayerMy from "../../player/PlayerMy";
import ResultService from "../../service/result.service";
import Countdown from "react-countdown";
import {selectAudio, selectImage, selectText, selectVideo} from "./template";

var stringSimilarity = require("string-similarity");
const config = require("../../../configs/config.json");


export default function DoExam() {
    const [exam, setExam] = useState(null);
    const [qNumber, setQNumber] = useState(-1);
    const [question, setQuestion] = useState(null);
    const [examResult, setExamResult] = useState(true);
    const [examResultActivityPart, setExamResultActivityPart] = useState(true);
    const [student, setStudent] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [duration, setDuration] = useState(Date.now() + 10000);

    const navigate = useNavigate();


    useEffect(() => {
        if (question != null) {
            setQuestion(JSON.parse(JSON.stringify(question)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examResultActivityPart]);


    useEffect(() => {
        const assignmentId = localStorage.getItem("assignmentId");
        ExamService.startExam(assignmentId).then((response) => {
            const result = response.data
            setExamResult(response.data.examResult);
            setExamResultActivityPart(response.data.examResultActivityPart);
            setStudent(response.data.student);
            setExam(response.data.exam);
            if(response.data.examResult.duration) setDuration(Date.now() + (response.data.examResult.duration * 1000 * 60));
            else setDuration(Date.now() + (response.data.exam.duration * 1000 * 60));

            if(response.data.examResult.duration === 0){
                navigate("/finish")
            }
            console.log(response.data.exam.duration)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log(examResult)

    useEffect(() => {
        if (exam && exam.activities && Array.isArray(exam.activities) && exam.activities.length > 0 && qNumber === -1) {
            setQNumber(0)
            setQuestion(exam.activities[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exam]);

    useEffect(() => {
        setQuestion(null)
        if (qNumber > -1) {
            setQuestion(exam.activities[qNumber])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qNumber]);


    const handleQuestionSelect = (value) => {
        if (value === -1 && qNumber > 0) {
            setQNumber(qNumber - 1)
        } else if (value === 1 && qNumber < exam.activities.length - 1) {
            setQNumber(qNumber + 1)
        }
    }

    const clearText = (readingText) =>
        String(readingText)
            .replace(/[.,!?'_-]/g, '')
            .toLowerCase();


    const getSpeechText = (text, questionNumber) => {
        const selectedAnswer = question.parts.find(part => part.id === questionNumber).answer
        const similarity = stringSimilarity.compareTwoStrings(clearText(text), clearText(selectedAnswer));
        const value = parseInt(similarity * 100)
        const activityPartResult = examResultActivityPart.find(part => part.activityPart.id === questionNumber)
        const score = activityPartResult.score;
        const studentScore = value * score / 100;

        if (!activityPartResult.studentScore || Number(studentScore) > Number(activityPartResult.studentScore)) {
            activityPartResult.recordRepeatCount = activityPartResult.recordRepeatCount ? activityPartResult.recordRepeatCount + 1 : 1
            activityPartResult.studentAnswer = text;
            activityPartResult.studentScore = studentScore;
            ResultService.addActivityPartResult(activityPartResult).then((response) => {
                setExamResultActivityPart(response.data)
            });
        } else {
            activityPartResult.recordRepeatCount = activityPartResult.recordRepeatCount ? activityPartResult.recordRepeatCount + 1 : 1
            ResultService.addActivityPartResult(activityPartResult).then((response) => {
                setExamResultActivityPart(response.data)
            });
        }

    };

    const findResultData = (ques) => {
        if (!ques.isMain) return null;
        const activityPartResult = examResultActivityPart.find(part => part.activityPart.id === ques.id)
        const activeActivity = question.parts.find(part => part.id === ques.id)
        return {
            repeatCount: activeActivity?.repeatCount,
            studentRepeatCount: activityPartResult?.repeatCount,
            recordRepeatCount: activityPartResult?.recordRepeatCount,
            studentScore: activityPartResult?.studentScore
        }
    }

    const handleEnded = (activityPartId) => {

        try {
            const activityPartResult = examResultActivityPart.find(part => part.activityPart.id === activityPartId)
            activityPartResult.repeatCount = activityPartResult.repeatCount ? activityPartResult.repeatCount + 1 : 1
            ResultService.addActivityPartResult(activityPartResult).then((response) => {
                setExamResultActivityPart(response.data)
            });
        } catch (e) {
            console.log(e)
        }
    }

    const onTick = (e) => {

        if(e.seconds % 10 === 0){
            const assignmentId = localStorage.getItem("assignmentId");
            ExamService.setExamDuration(assignmentId,e.minutes).then((response) => {
                console.log(response.data.exam.duration)
            });

        }


    }
    const onComplete = () => {
        navigate("/finish")
    }
    const TimeOut = () => <span></span>;

    const renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            return <TimeOut/>;
        } else {
            return (
                <span>{minutes} minutes</span>
            );
        }
    };

    const ExamHeader = ({exam}) => (

        <div className="row justify-content-center mt-3">
            <div className="col-12">
                <div className="card border-0 shadow p-4 mb-4">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h3 className="fw-bold">{exam.name}</h3>
                        <h3 style={{color: "red"}}>
                            <Countdown date={duration} onTick={onTick} onComplete={onComplete}
                                       renderer={renderer}/>
                        </h3>

                    </div>
                    <p className="m-0">{exam.explain}</p>
                </div>
            </div>
        </div>
    );

/*
    const selectAudio = (ques) => {
        const resultData = findResultData(ques)
        const url = getUrl(ques.url)
        return (
            <React.Fragment>

                {
                    ques.title ? <div className="d-flex align-items-center justify-content-center m-2">
                        <h5>{ques.title}</h5>
                    </div> : null
                }


                <div className="d-flex align-items-center justify-content-center m-2">
                    {
                        ques.isMain === false || resultData?.repeatCount !== resultData?.studentRepeatCount ?
                            <PlayerMy key={ques.id} url={`${config.api.invokeUrl}/storage/preview/file/${ques.url}`} handleEnded={handleEnded}
                                      activityPArtId={ques.id}/> : null
                    }


                </div>


                <div className="d-flex align-items-center justify-content-center m-2">


                    {
                        resultData?.repeatCount && resultData.repeatCount > 0 ? <div
                            className="badge bg-success me-2">Listening
                            opportunity {resultData?.repeatCount} -{resultData?.studentRepeatCount}</div> : null
                    }
                </div>


                {
                    ques.content ? <div className="d-flex align-items-center justify-content-center m-2">
                        <h5>{ques.content}</h5>
                    </div> : null
                }
            </React.Fragment>

        )
    }

    const getUrl = (url) => {
        return `${config.api.baseUrl}/sample/${url}`
    }
    const selectImage = (ques) => {
        const resultData = findResultData(ques)
        return (
            <React.Fragment>
                {
                    ques.title ? <div className="d-flex align-items-center justify-content-center m-2">
                        <h5>{ques.title}</h5>
                    </div> : null
                }
                <div className={"text-center"}>
                    <img src={`${config.api.invokeUrl}/storage/preview/file/${ques.url}`} style={{height: "250px"}} alt="image"/>
                </div>
            </React.Fragment>
        )
    }
    const selectVideo = (ques) => {
        const resultData = findResultData(ques)
        return (
            <React.Fragment>
                {
                    ques.title ? <div className="d-flex align-items-center justify-content-center m-2">
                        <h5>{ques.title}</h5>
                    </div> : null
                }
                <div className={"d-flex justify-content-center align-items-center mb-2"}>

                    <PlayerMy key={ques.id} title={ques.title} url={`${ques.url}`} style={{height: "250px"}}/>
                </div>
            </React.Fragment>
        )
    }
    const selectText = (ques) => {
        const resultData = findResultData(ques)

        const lineList = ques.content.split("\n");
        return (
            <div className={"text-left"}>
                <h5>{
                    lineList.map((line, key) =>
                        <div key={key}>{line}</div>
                    )
                }
                </h5>
            </div>
        )
    }

 */
    const compare = (a, b) => {
        if (a.orderNumber < b.orderNumber) {
            return -1;
        }
        if (a.orderNumber > b.orderNumber) {
            return 1;
        }
        return 0;
    };


// <Link to={`/exam/${id}/${question.id}/${activityPart.id}`}> <FaRegEdit cursor={"pointer"} size={24} onClick={() => handleQuestionSelect(-1)} className="m-2"/></Link>

    const handleShow = () => {
        setShowModal(true);
    }
    const handleClose = () => {
        setQNumber(0);
        setShowModal(false);
    }


    const handleFinishClose = () => {

        const assignmentId = localStorage.getItem("assignmentId");
        ExamService.setExamDuration(assignmentId,0).then((response) => {
            console.log(response.data.exam.duration)
        });
        navigate("/finish")

    }



    const makeChoice = (key, ques) => {

        const resultData = findResultData(ques)
        return (
            //<div className={`form-check alert alert-light border ${question.answer === key + "" ? "border-info" : "border-success"}  w-100`} role="alert">
            <div key={key}
                 className={`form-check alert alert-light ${ques.isMain ? "border border-info " : "border border-gray "} w-100`}>
                {
                    ques.type === "TEXT" ? selectText(ques) :
                        ques.type === "AUDIO" ? selectAudio(ques) :
                            ques.type === "VIDEO" ? selectVideo(ques) :
                                ques.type === "IMAGE" ? selectImage(ques) :
                                    null
                }
                {
                    ques.isMain ? <>
                            <SpeechText key={ques.id}
                                        getSpeechText={getSpeechText}
                                        questionNumber={ques.id}
                                        recordRepeatCount={resultData?.recordRepeatCount}
                                        repeatCount={resultData?.repeatCount}
                            />

                            <div className="d-flex align-items-center justify-content-start m-2">


                                {
                                    resultData?.repeatCount && resultData.repeatCount > 0 ? <div
                                        className="badge bg-success me-2">Recording
                                        opportunity {resultData?.repeatCount} -{resultData?.recordRepeatCount}</div> : null
                                }
                            </div>

                        </> :
                        null

                }


            </div>
        )
    }


    const PaginationPanel2 = () => {
        return (
            <div
                className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <nav aria-label="Page navigation example">
                    <ul className="pagination mb-0">
                        <li className="page-item">
                            <button className="page-link"
                                    onClick={() => handleQuestionSelect(-1)}>Previous
                            </button>
                        </li>

                        {
                            exam && exam.activities && Array.isArray(exam.activities) ?
                                exam.activities.sort(compare).map((exam, key) =>
                                    <li key={key} className={`page-item`}>
                                        <button
                                            className={`page-link ${qNumber === key ? "bg-success" : !exam.answer && exam.answer === "" ? "" : "bg-gray-100"}`}
                                            onClick={() => setQNumber(key)}>{key + 1}</button>
                                    </li>
                                ) : null
                        }
                        <li className="page-item">

                            <button className="page-link" onClick={handleShow}>MODAL</button>
                            {
                                qNumber < exam.activities.length - 1 ?

                                    <button className="page-link"
                                            onClick={() => handleQuestionSelect(1)}>Next
                                    </button>
                                    :
                                    <button className="btn-danger page-link w-100" onClick={handleShow}>Finish Exam</button>
                            }
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }


    const PaginationPanel = () => {
        return (
            <div
                className="px-3">

                <div className="row w-100">
                    <div className="col w-100 ">
                        <button className="page-link w-100"
                                onClick={() => handleQuestionSelect(-1)}>Previous
                        </button>
                    </div>

                    {
                        exam && exam.activities && Array.isArray(exam.activities) ?
                            exam.activities.sort(compare).map((exam, key) =>
                                <div key={key} className={`col w-100`}>
                                    <button
                                        className={`page-link w-100 mb-1 ${qNumber === key ? "bg-success" : !exam.answer && exam.answer === "" ? "" : "bg-gray-100"}`}
                                        onClick={() => setQNumber(key)}><b>{key + 1}</b></button>
                                    <button style={{height: "40px"}}
                                            className={`page-link w-100 ${qNumber === key ? "bg-success" : !exam.answer && exam.answer === "" ? "" : "bg-gray-100"}`}
                                            onClick={() => setQNumber(key)}>
                                        <div
                                            style={{
                                                fontSize: "10pt",
                                                lineHeight: "100%"
                                            }}>{exam.activityGroup.replaceAll("_", " ")} </div>
                                    </button>
                                </div>
                            ) : null
                    }
                    <div className="col w-100 page-item">
                        {
                            qNumber < exam.activities.length - 1 ?

                                <button className="page-link w-100"
                                        onClick={() => handleQuestionSelect(1)}>Next
                                </button>
                                :

                            <button className="btn-danger page-link w-100" onClick={handleShow}>Finish Exam</button>
                    }
                </div>
            </div>

            </div>
        )
    }


    const PaginationOnlyNext = () => {
        return (
            <div
                className="px-3">

                <div className="row w-100">

                    <div className="page-item text-center">


                        {
                            qNumber < exam.activities.length - 1 ?
                                <button className="btn btn-success"
                                        onClick={() => handleQuestionSelect(1)}><span className="fw-bold fs-3">Next Section</span>
                                </button>
                                :

                                <button className="btn btn-danger" onClick={handleShow}><span
                                    className="fw-bold fs-3">Finish</span></button>
                        }
                    </div>
                </div>

            </div>
        )
    }

    const calculateQuestionCount = (section) => {
        let count = 0;
        for (let i = 0; i < section.parts.length; i++) {
            if (section.parts[i].isMain) {
                count += 1;
            }
        }


        if(count>1) return count + " Questions";

        return count+ " Question";
    }

    const QuestionPanel = (question_data) => {
        return (
            <React.Fragment>
                {
                    question && question.parts && question.parts.length > 0 ?
                        <React.Fragment>
                            <PaginationPanel/>

                            <div className="row justify-content-center mt-3" key={`${question.id}-divq`}>
                                <div className="col-12">
                                    <div className="card border-2 border-success shadow p-4 mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-2">

                                            <h4 className="fw-bold">SECTION {(qNumber + 1)} : {question.activityGroup.replaceAll("_", " ")}</h4>
                                            <h4 style={{color: "red"}}>{calculateQuestionCount(question)}</h4>
                                        </div>
                                        {
                                            question && question.parts && Array.isArray(question.parts) ?
                                                question.parts.sort(compare).map((ques, key) =>
                                                    <div key={key} className="mt-2">
                                                        <div className="col-lg-3 col-sm-6 mt-4 mt-md-0 w-100">
                                                            <fieldset className="w-100">
                                                                {
                                                                    makeChoice(key, ques)
                                                                }
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                )

                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <PaginationOnlyNext/>

                        </React.Fragment>
                        : null
                }
            </React.Fragment>
        )
    }


    return (
        <div className={`container mt-5 ${showModal ? "modal-open" : ""}`}>


            {
                question ?
                    <ExamHeader exam={exam}/>
                    : null
            }
            {
                qNumber > -1 && question ?
                    QuestionPanel(question)
                    : null
            }


            {showModal && (
                <>
                    {/* Blur Effect */}
                    <div className="backdrop"></div>

                    <div className="modal show d-block" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Ready Quit</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={handleClose}
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <p>Once you log out of the exam, you cannot log in again.</p>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={handleClose}
                                    >
                                        CHECK YOUR ANSWERS AGAIN
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={handleFinishClose}
                                    >
                                        Finish
                                    </button>



                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}