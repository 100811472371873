import React, {useRef, useState} from "react";
import ReactPlayer from "react-player";
import {FaPlayCircle} from "react-icons/fa";
import audioImagePlay from "../../assets/dalga.gif";
import audioImage from "../../assets/dalga.png";


// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
    const {handleEnded, activityPArtId, title, format} = props;
    const [url, setUrl] = useState(props.url)
    const [pip, setPip] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [controls, setControls] = useState(false)
    const [light, setLight] = useState(false)
    const [volume, setVolume] = useState(0.8)
    const [muted, setMuted] = useState(false)
    const [played, setPlayed] = useState(0)
    const [loaded, setLoaded] = useState(0)
    const [duration, setDuration] = useState(0)
    const [playbackRate, setPlaybackRate] = useState(1.0)
    const [loop, setLoop] = useState(false)
    const [seeking, setSeeking] = useState(false)
    const SEPARATOR = ' · '

    const audioImage = require('../../assets/dalga.png')
    const audioImagePlay = require('../../assets/dalga.gif')


    const [image, setImage] = useState(audioImage)


    const player = useRef(null)

    const load = url => {

        setUrl(url)
        setPlayed(0)
        setLoaded(0)
        setPip(false)

    }


    const handlePlayPause = () => {
        setPlaying(!playing)
    }

    const handleStop = () => {
        setPlaying(false)
        setUrl(null)

    }

    const handleToggleControls = () => {
        setControls(!controls)
        load(url)

    }

    const handleToggleLight = () => {
        setLight(!light)
    }

    const handleToggleLoop = () => {
        setLoop(!loop)

    }

    const handleVolumeChange = e => {
        setVolume(parseFloat(e.target.value))
    }

    const handleToggleMuted = () => {
        setMuted(!muted)
    }

    const handleSetPlaybackRate = e => {
        setPlaybackRate(parseFloat(e.target.value))
    }

    const handleOnPlaybackRateChange = (speed) => {
        setPlaybackRate(parseFloat(speed))
    }

    const handleTogglePIP = () => {
        setPip(!pip)
    }

    const handlePlay = () => {
        //console.log('onPlay')
        setPlaying(true)
    }

    const handleEnablePIP = () => {
        //console.log('onEnablePIP')
        setPip(true)
    }

    const handleDisablePIP = () => {
        //console.log('onDisablePIP')
        setPip(false)
    }

    const handlePause = () => {
        //console.log('onPause')
        setPlaying(false)
    }

    const handleSeekMouseDown = e => {
        setSeeking(true)
    }

    const handleSeekChange = e => {
        setPlayed(parseFloat(e.target.value))
    }

    const handleSeekMouseUp = e => {
        setSeeking(false)
        player.seekTo(parseFloat(e.target.value))
    }

    const handleProgress = state => {
        //console.log('onProgress', state)

        setPlayed(state.playedSeconds)
        setLoaded(state.loadedSeconds)
        // burası boş
    }

    const handleDuration = (duration) => {
        //console.log('onDuration', duration)
        setDuration(duration)
    }

    const handleClickFullscreen = () => {
        //  screenfull.request(document.querySelector('.react-player'))
    }

    const renderLoadButton = (url, label) => {
        return (
            <button onClick={() => load(url)}>
                {label}
            </button>
        )
    }

    const ref = player => {
        player = player
    }

    return (
        <div className='app player-wrapper' style={{width: title === "WATCH THE VIDEO" ? "100%" : "100%"}}>
            <div className=''>
                {
                    url && false &&(
                        <div className='d-flex justify-content-center'>
                            <button onClick={handlePlayPause}>{playing ? 'Pause' : 'Play'}</button>
                            <button onClick={handleStop}>Stop</button>
                            <button onClick={handleToggleControls}>{controls ? 'Hide' : 'Show'} Controls</button>
                            <button onClick={handleToggleLight}>{light ? 'On' : 'Off'} Light</button>
                            <button onClick={handleToggleLoop}>{loop ? 'Unloop' : 'Loop'}</button>
                            <button onClick={handleToggleMuted}>{muted ? 'Unmute' : 'Mute'}</button>
                            <button onClick={handleTogglePIP}>{pip ? 'Disable PiP' : 'Enable PiP'}</button>
                            <button onClick={handleClickFullscreen}>Fullscreen</button>
                        </div>
                    )
                }


                {
                    format === "mp3" ?
                        <img src={image} alt={"audio"} style={{width: "100%"}}/>
                        :null
                }
                {

                    format === "mp3" ?
                        <ReactPlayer
                            ref={player}
                            className={`react-player`}
                            width={'100%'}
                            height={"0px"}
                            url={url}
                            pip={pip}
                            playing={playing}
                            controls={controls}
                            loop={loop}
                            playbackRate={playbackRate}
                            onReady={() => console.log('onReady')}
                            onStart={() => {
                                setImage(audioImagePlay)
                            }}
                            onSeek={e => console.log('onSeek', e)}
                            onBuffer={() => console.log('onBuffer')}
                            onError={e => console.log('onError', e)}
                            onPlay={() => {
                                setImage(audioImagePlay)
                                handlePlay()
                            }}
                            playerOptions={true}
                            onEnablePIP={handleEnablePIP}
                            onDisablePIP={handleDisablePIP}
                            onPause={handlePause}
                            onPlaybackRateChange={handleOnPlaybackRateChange}
                            onEnded={() => {
                                setImage(audioImage)
                                handleEnded(activityPArtId)
                            }}
                            onProgress={handleProgress}
                            onDuration={handleDuration}
                            onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}

                        />:
                        <ReactPlayer
                            ref={player}
                            className={`react-player`}
                            width={'100%'}
                            url={url}
                            pip={pip}
                            playing={playing}
                            controls={controls}
                            loop={loop}
                            playbackRate={playbackRate}
                            onReady={() => console.log('onReady')}
                            onStart={() => {
                                setImage(audioImagePlay)
                            }}
                            onSeek={e => console.log('onSeek', e)}
                            onBuffer={() => console.log('onBuffer')}
                            onError={e => console.log('onError', e)}
                            onPlay={() => {
                                setImage(audioImagePlay)
                                handlePlay()
                            }}
                            playerOptions={true}
                            onEnablePIP={handleEnablePIP}
                            onDisablePIP={handleDisablePIP}
                            onPause={handlePause}
                            onPlaybackRateChange={handleOnPlaybackRateChange}
                            onEnded={() => {
                                setImage(audioImage)
                                handleEnded(activityPArtId)
                            }}
                            onProgress={handleProgress}
                            onDuration={handleDuration}
                            onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}

                        />



                }


                <div className="d-flex justify-content-center">
                    <FaPlayCircle size={40} color={`${playing === true ? "gray" : "blue"}`}
                                  onClick={() => setPlaying(true)} style={{cursor: "pointer"}}/>
                </div>
            </div>
        </div>
    )

}
