import React, {useContext, useEffect, useState} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {AppContext} from "../../../configs/AppContextProvider";
import {LuPencilLine} from "react-icons/lu";
import {VscDebugStart} from "react-icons/vsc";
import ExamService from "../../service/exam.service";
import PlayerMy from "../../player/PlayerMy";



export default function Exam() {
    const [exam, setExamData] = useState([]);
    const navigate = useNavigate();


    const [ready, setRready] = useState(false);


    const [isFinish, setIsFinish] = useState(false);

    const {student} = useContext(AppContext);



    const getReady = () => {
        setRready(true);
    }


    useEffect(() => {

        if(!student || student.name === undefined){
            navigate("/error/studentNotFound");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const assignmentId = localStorage.getItem("assignmentId");
        ExamService.startExam(assignmentId).then((response) => {
            setExamData(response.data.exam);
            const duration = response.data?.examResult?.duration;

            try{
                console.log(duration)
                if(duration === 0){
                    console.log("Geldi")
                    navigate("/finish");
                }
                else{
                    console.log("Gelmedi")
                }
            }catch (e) {

            }


        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const calculateQuestionCount=(section)=>{
        let count = 0;
        for(let i=0; i<section.parts.length; i++){
            if(section.parts[i].isMain){
                count += 1;
            }
        }

        if(count>1) return count + " Questions";

        return count+ " Question";
    }


    const typeToText = (type) => {

        if(type==="WARM_UP") return "WARM UP – There is no marking for this section.";
        if(type==="LISTENING_AND_PRONUNCIATION") return "LISTENING AND PRONUNCIATION.";
        if(type==="TEXT_READING_AND_SPEAKING") return "TEXT READING AND SPEAKING.";
        if(type==="IMAGE_ANALYSIS") return "IMAGE ANALYSIS.";
        if(type==="VIDEO_ANALYSIS") return "VIDEO ANALYSIS.";
        return type;


    }

    const examRow = () => {

        return (
            <>

                <div className="container">
                    <div className="table-wrapper table-responsive mt-3 w-100">


                        <p className="btn btn-success text-white w-100" to="/"><h3>
                            Hello {student?.name} {student?.lastName}. If you are ready, we can start.
                        </h3></p>

                    </div>
                </div>


                <div className="container mt-5 mb-3">
                    <div className="row">


                        <div className="col-md-8">
                            <div className="card p-3 mb-2">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="icon"><LuPencilLine color={"blue"}/></div>
                                        <div className="ms-2 c-details">
                                            <h6 className="mb-0">{student?.name} {student?.lastName}</h6>
                                            <span>{student?.school} </span><span> {student?.grade} </span><span> {student?.branch} </span>
                                        </div>
                                    </div>
                                    <div className="badge"><span>{exam?.status}</span></div>
                                </div>
                            </div>

                            <div className="mt-5">
                                <h3 className="heading">{exam?.name}<br/></h3><br/>
                                <h5>{exam?.explain} </h5>

                                <h5 style={{color: "red"}}><br/><b>IMPORTANT INFORMATION</b><br/>
                                    The total duration is given on the screen.<br/>
                                    The time will start when you press start the exam button.

                                    <hr/></h5>

                                <h5 style={{color: "darkblue"}}>
                                    {
                                        exam?.activities?.map((e, key) => (
                                                <>
                                                    {
                                                        //SECTION {key + 1} : {e.activityGroup.replaceAll("_", " ")} - Question Count: {calculateQuestionCount(e)} <br/>
                                                    }
                                                    SECTION {key + 1} : {typeToText(e.activityGroup)}  <br/>

                                                </>

                                            )
                                        )
                                    }
                                </h5>
                            </div>

                        </div>

                        <div className="col-md-4">
                            <div className="d-flex justify-content-end">
                                <PlayerMy url={`https://vimeo.com/1040044954`} style={{height: "350px"}} handleEnded={getReady}/>
                            </div>


                            <div className="d-flex justify-content-center mt-2">
                                <div className="clock" style={{
                                    backgroundImage: "url('./assets/clock.png')",
                                    height: "180px",
                                    width: "180px"
                                }}>
                                    <span className={"clock-text-box"}>{exam.duration}</span>
                                </div>
                            </div>
                            <br/>
                            <div className="d-flex justify-content-center mx-3">
                                <h5>
                                    TOTAL TIME <br/>
                                    {exam.duration} MINUTES
                                </h5>
                            </div>
                            <div className="d-flex justify-content-end mt-5">
                                {
                                    ready ? <Link className="btn btn-success text-white" to={`/exam/${exam?.id}`}>
                                        <VscDebugStart size={28}/>
                                        <span className={"p-3"}>Start Exam</span>
                                    </Link> : null
                                }

                            </div>
                        </div>

                    </div>
                    </div>


                </>
                )
    };


    return (
        <div className="container">
            {student === null || exam === null ? (
                <Navigate to="/error/studentNotFound"/>
            ) : null}
            <div className="card card-body shadow border-0 table-wrapper table-responsive mt-3">

                {exam ? examRow() : null}
            </div>
        </div>
    );
}
